import { createGlobalStyle } from "styled-components";
const Estiloglobal=createGlobalStyle`

* {
    
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    
} 
body{
    width:100vw;
    height:100vh;
    background-color:#ffc24d;
    font-style:italic;

}

`
export default Estiloglobal