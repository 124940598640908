import styled from "styled-components"

export const BotaoCustomizado=styled.button`

color:#000;
fontsize:20px;
background-color:#ffc24d;
border-radius:8px;
box-shadow:0 4px 8px 0 #132544, 0 4px 8px 0 #132544;
width:100%;
max-width:200px;
height:40px;
cursor: pointer;



`