import styled from "styled-components"

export const NavbotaoCustomizado=styled.button`


background-color:#6ec285;
width:50px;
height:30px;
border:none;
cursor: default;
`